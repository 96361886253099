<template>
  <div class="wrap">
    <!-- 头部 -->
    <section class="header">
      <img class="title" v-if="title" :src="title" alt="" />
      <img class="title" v-if="description" :src="description" alt="" />
      <img class="main" v-if="mainImg" :src="mainImg" alt="" />
    </section>
    <!-- 联系我们 -->
    <section class="contact">
      <div class="contact-box">
        <div class="title">{{ contact.text }}</div>
        <div class="text">
          <span>{{ contact.content }}</span>
        </div>
        <el-form ref="form" :model="form" class="form">
          <el-form-item prop="name">
            <el-input @input="(e) => handleInput(e, 'name')" :maxlength="30" v-model="form.name" :placeholder="$t('m.20004')"></el-input>
          </el-form-item>
          <el-form-item prop="mobile">
            <el-input @input="(e) => handleInput(e, 'mobile')" :maxlength="13" v-model="form.mobile" :placeholder="$t('m.20006')"></el-input>
          </el-form-item>
          <div class="agree" v-if="checkTrue && checkFalse" @click="checked = !checked">
            <img :src="checkTrue" alt="" v-if="checked" />
            <img :src="checkFalse" alt="" v-else />
            <span>{{ userInfo.text }}</span>
          </div>
          <el-form-item style="margin-bottom: 0">
            <el-button @click="onSubmit('form')">{{ userInfo.content }}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </section>
    <section>
      <!-- 功能特点 -->
      <section class="icatering function-features">
        <div class="title">
          <img v-if="functionFeatures" :src="functionFeatures" alt="" />
        </div>
        <div class="content">
          <div class="content-item" v-for="(item, index) in functionFeaturesList" :key="index">
            <img :src="item.image" alt="" />
            <p>{{ item.text }}</p>
          </div>
        </div>
      </section>
      <!-- 业务场景 -->
      <section class="icatering business-scenario">
        <div class="title">
          <img v-if="businessScenario" :src="businessScenario" alt="" />
        </div>
        <div class="content">
          <div class="content-item" v-for="(item, index) in businessScenarioList" :key="index">
            <img :src="item.image" alt="" />
            <div class="content-item-r">
              <p class="title">{{ item.text }}</p>
              <p>{{ item.content }}</p>
            </div>
          </div>
        </div>
      </section>
      <!-- 餐饮优势 -->
      <section class="icatering catering-advantage">
        <div class="title">
          <img v-if="cateringAdvantage" :src="cateringAdvantage" alt="" />
        </div>
        <div class="content">
          <div class="content-item" v-for="(item, index) in cateringAdvantageList" :key="index">
            <img :src="item.image" alt="" />
            <p>{{ item.text }}</p>
          </div>
        </div>
      </section>

      <!-- 餐饮设备 -->
      <section class="icatering catering-device">
        <div class="title">
          <img v-if="cateringDevice" :src="cateringDevice" alt="" />
        </div>
        <div class="content">
          <div class="content-item" v-for="(item, index) in cateringDeviceList" :key="index">
            <img :src="item.image" alt="" />
            <p>{{ item.text }}</p>
          </div>
        </div>
      </section>
      <!-- 场景覆盖 -->
      <section class="icatering scene-coverage">
        <div class="title">
          <img v-if="sceneCoverage" :src="sceneCoverage" alt="" />
        </div>
        <div class="content">
          <div class="content-item" v-for="(item, index) in sceneCoverageList" :key="index">
            <div class="img-box">
              <img :src="item.image" alt="" />
            </div>
            <p>{{ item.text }}</p>
          </div>
        </div>
      </section>
    </section>
    <section class="footer" :class="{ hideshow: !hideshow }">
      <div class="item">
        <p>{{ company }}</p>
      </div>
      <div class="item">
        <img v-if="guarantee.image" :src="guarantee.image" alt="" />
        <p>
          {{ guarantee.text }}<a v-if="applyGuarantee.content" :href="applyGuarantee.content" target="_blank">{{ applyGuarantee.text }}</a>
        </p>
      </div>
      <div class="item">
        <div class="item-t" v-for="(item, index) in guaranteeList" :key="index">
          <img :src="item.image" alt="" />
          <p>{{ item.text }}</p>
        </div>
      </div>
    </section>
    <div class="box" :class="{ hideshow: !hideshow }">
      <div class="content" :class="{ 'first-screen': isFirst }" @click="isFirst = true">
        <div class="content-t">
          <img :src="logo" alt="i餐饮" />
        </div>
        <div class="content-m">
          <div class="content-m-title">i餐饮</div>
          <div class="rate">
            <van-rate v-model="value" :size="14" allow-half readonly color="#ff7c2a" void-icon="star" void-color="#eee" /><span class="count">{{ value.toFixed(1) }}</span>
          </div>
          <div class="content-m-desc">懂营销的智慧餐饮管理系统</div>
        </div>
        <div class="content-b" @click.stop="visible = true">立即下载</div>
        <div class="content-text">
          <p>版本 {{ icatering.versionName }}；更新于{{ icatering.updateDate }}</p>
          <p>{{ company }} <a href="javascript:;">隐私</a>｜<a href="javascript:;">权限</a></p>
        </div>
      </div>
    </div>
    <el-dialog v-loading :lockScroll="false" @open="openMenu" @close="closeMenu" :showClose="false" class="down-box" :visible.sync="visible" width="90%" top="20vh">
      <div class="logo">
        <img :src="logo" alt="i餐饮" />
      </div>
      <div class="title">懂营销的智慧餐饮管理系统</div>
      <div class="list">
        <div class="item">
          <div class="item-l"><img src="@/assets/myimg/icatering.png" alt="i餐饮" /></div>
          <div class="item-r">
            <p>适用于手机/手持收银机</p>
            <div class="btn" @click="download(0)">立即下载i餐饮</div>
          </div>
        </div>
        <div class="item">
          <div class="item-l"><img src="@/assets/myimg/icateringHd.png" alt="i餐饮HD" /></div>
          <div class="item-r">
            <p>适用于平板/收银一体机</p>
            <div class="btn" @click="download(1)">立即下载i餐饮HD</div>
          </div>
        </div>
      </div>
    </el-dialog>
    <div class="arrow-box" :class="{ show: isWeChat }" @click="isWeChat = false">
      <div class="arrow">
        <img src="@/assets/myimg/arrow.png" alt="arrow" />
      </div>
      <div class="content">
        <p>1、点击右上角 “<img src="@/assets/myimg/menu.png" alt="menu" />”</p>
        <p>2、选择“在浏览器中打开”</p>
      </div>
    </div>
  </div>
</template>

<script>
import Toast from 'vant/lib/toast'
import 'vant/lib/toast/style'
import { mapGetters } from 'vuex'
import Vue from 'vue'
import Rate from 'vant/lib/rate'
import 'vant/lib/rate/style'
import { isWeixin, getPhoneBrand } from '@/utils/utils'
Vue.use(Rate)
export default {
  name: 'Spread',
  data() {
    return {
      form: {
        name: '',
        mobile: '',
        notes: '',
        platform: 'h5',
        email: 'xxx@xxx.xxx',
        source: 9,
      },
      rules: {
        name: [{ required: false }],
        mobile: [{ required: false }],
      },
      checked: false,
      spreadConfig: {},
      docmHeight: document.documentElement.clientHeight || document.body.clientHeight,
      showHeight: document.documentElement.clientHeight || document.body.clientHeight,
      hideshow: true,
      value: 5,
      isFirst: true,
      visible: false,
      isWeChat: false,
      apkId: 'com.ihk.merchant', // i餐饮包名
      apkHdId: 'com.ihk.merchant.machine',
      url: {
        icatering: '/admin/app/android/version/0/2',
        icateringHd: '/admin/app/android/version/20/2',
      },
      loading: false,
      icatering: {},
      icateringHd: {},
      // 手机品牌跳转商店列表
      brandList: {
        vivo: 'vivoMarket://details?id=',
        huawei: 'appmarket://details?id=',
        mi: 'mimarket://details?id=',
        oppo: 'oppomarket://details?packagename=',
        tencent: 'tmast://appdetails?pname=',
      },
      type: 0,
      brand: '',
      timer: null,
    }
  },
  computed: {
    ...mapGetters({
      appData: 'app/appData',
    }),
    // logo
    logo() {
      return (this.appData.logo && this.appData.logo.content) || ''
    },
    title() {
      return (this.spreadConfig.title && this.spreadConfig.title.content) || ''
    },
    description() {
      return (this.spreadConfig.description && this.spreadConfig.description.content) || ''
    },
    mainImg() {
      return (this.spreadConfig.mainImg && this.spreadConfig.mainImg.content) || ''
    },
    contact() {
      return (this.spreadConfig.contact && this.spreadConfig.contact.content) || {}
    },
    userInfo() {
      return (this.spreadConfig.userInfo && this.spreadConfig.userInfo.content) || ''
    },
    checkFalse() {
      return (this.spreadConfig.checkFalse && this.spreadConfig.checkFalse.content) || ''
    },
    checkTrue() {
      return (this.spreadConfig.checkTrue && this.spreadConfig.checkTrue.content) || ''
    },
    functionFeatures() {
      return (this.spreadConfig.functionFeatures && this.spreadConfig.functionFeatures.content) || ''
    },
    functionFeaturesList() {
      return (this.spreadConfig.functionFeaturesList && this.spreadConfig.functionFeaturesList.content.list) || []
    },
    businessScenario() {
      return (this.spreadConfig.businessScenario && this.spreadConfig.businessScenario.content) || ''
    },
    businessScenarioList() {
      return (this.spreadConfig.businessScenarioList && this.spreadConfig.businessScenarioList.content.list) || []
    },
    cateringAdvantage() {
      return (this.spreadConfig.cateringAdvantage && this.spreadConfig.cateringAdvantage.content) || ''
    },
    cateringAdvantageList() {
      return (this.spreadConfig.cateringAdvantageList && this.spreadConfig.cateringAdvantageList.content.list) || []
    },
    cateringDevice() {
      return (this.spreadConfig.cateringDevice && this.spreadConfig.cateringDevice.content) || ''
    },
    cateringDeviceList() {
      return (this.spreadConfig.cateringDeviceList && this.spreadConfig.cateringDeviceList.content.list) || []
    },
    sceneCoverage() {
      return (this.spreadConfig.sceneCoverage && this.spreadConfig.sceneCoverage.content) || ''
    },
    sceneCoverageList() {
      return (this.spreadConfig.sceneCoverageList && this.spreadConfig.sceneCoverageList.content.list) || []
    },
    company() {
      return (this.appData.company && this.appData.company.content) || ''
    },
    guarantee() {
      return (this.spreadConfig.guarantee && this.spreadConfig.guarantee.content) || {}
    },
    applyGuarantee() {
      return (this.spreadConfig.applyGuarantee && this.spreadConfig.applyGuarantee.content) || {}
    },
    guaranteeList() {
      return (this.spreadConfig.guaranteeList && this.spreadConfig.guaranteeList.content.list) || []
    },
    appDownload() {
      return (this.spreadConfig.appDownload && this.spreadConfig.appDownload.content) || {}
    },
  },
  watch: {
    //监听显示高度
    showHeight(newValue) {
      this.hideshow = this.docmHeight <= newValue
    },
  },
  created() {
    this.$api.getSpreadConfig({ platform: navigator.userAgent.includes('Mobile') ? 'h5' : 'pc', source: 8 }).then((res) => {
      if (res.code == 200) {
        this.spreadConfig = res.data
      }
    })
    this.getIcatering()
    this.getIcateringHd()
    this.getMobile()
  },
  mounted() {
    this.importJS()
    //监听事件
    window.onresize = () => {
      return (() => {
        this.showHeight = document.documentElement.clientHeight || document.body.clientHeight
      })()
    }
    window.addEventListener('scroll', this.scrollEvent, false)

    window.addEventListener('visibilitychange', this.handlePagehide)
  },

  beforeDestroy() {
    this.timer = null
    window.removeEventListener('scroll', this.scrollEvent)
    window.addEventListener('visibilitychange', this.handlePagehide)
  },

  methods: {
    handlePagehide() {
      if (isWeixin()) {
        clearTimeout(this.timer)
        if (document.visibilityState == 'visible') {
          this.isWeChat = false
        }
      }
    },
    linkDown(href) {
      if (!href) return Toast('暂无资源')
      let a = document.createElement('a')
      a.href = href
      a.click()
    },
    /**
     * apkType: 0:icatering 1:icateringHd
     */
    download(apkType) {
      this.loading = true
      if (isWeixin()) {
        if (this.type === 1) {
          let attr = apkType ? 'downloadLinkHD' : 'downloadLink'
          let downloadLink = 'http://mp.weixin.qq.com/mp/redirect?url=' + this.appData[attr] && this.appData[attr].content
          this.linkDown(downloadLink)
          this.timer = setTimeout(() => {
            this.isWeChat = true
          }, 500)
        } else {
          this.isWeChat = true
        }
        this.loading = false
        return
      }

      if (this.type === 0) {
        //安卓
        let path = this.icatering.path
        let apkId = this.apkId
        if (apkType) {
          path = this.icateringHd.path
          apkId = this.apkHdId
        }
        this.brand = getPhoneBrand()
        if (this.brand != 'other') {
          this.linkDown(this.brandList[this.brand] + apkId)
        } else {
          this.linkDown(path)
        }
      } else {
        let attr = apkType ? 'downloadLinkHD' : 'downloadLink'
        let downloadLink = (this.appData[attr] && this.appData[attr].content) || null
        this.linkDown(downloadLink)
      }
      setTimeout(() => {
        this.loading = false
      }, 2000)
    },
    importJS() {
      let routeName = this.$route.name
      if (['ad1', 'ad2', 'ad3', 'ad5', 'ad6'].includes(routeName)) {
        let baseUrl = 'https://hm.baidu.com/hm.js?'
        let src = ''
        switch (routeName) {
          case 'ad1':
            src = '0a0b0b52b7ad69ba5e16872585cd8d34'
            break
          case 'ad2':
            src = '041b07ca4cdebff237dda2da54bbc1d7'
            break
          case 'ad3':
            src = '98c9346ff4c345b56ea7b1b92b0a755e'
            break
          case 'ad5':
            src = '3fb869cb268b6d4482bdd0d6cddec7a0'
            break
          case 'ad6':
            src = '36f962b85d75e5d1da9174f6570de524'
            break
        }
        let _hmt = _hmt || []
        let hm = document.createElement('script')
        hm.src = baseUrl + src
        let s = document.getElementsByTagName('script')[0]
        s.parentNode.insertBefore(hm, s)
      }
    },
    async getIcatering() {
      const { success, result } = await this.$axios({
        url: this.url.icatering,
      })
      if (success) {
        this.icatering = result
        let arr = result.updateDate.split('-')
        ;[arr[2], arr[0]] = [arr[0], arr[2]]
        this.icatering.updateDate = arr.join('-')
      }
    },
    async getIcateringHd() {
      const { success, result } = await this.$axios({
        url: this.url.icateringHd,
      })
      if (success) {
        this.icateringHd = result
      }
    },
    // 监听页面滚动函数
    scrollEvent() {
      let scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop || 0
      this.isFirst = scrollTop == 0
    },
    handleInput(value, attr) {
      let reg = /[^\w\u4E00-\u9FA5-\s]/g
      if (attr == 'mobile') {
        reg = /[^\d]/g
      }
      let val = value.replace(reg, '')
      this.form[attr] = val
    },
    onSubmit(formName) {
      if (!this.checked) {
        return Toast('需勾选同意《个人信息获取》')
      }
      if (!this.form['name']) {
        return Toast(this.$t('m.20004'))
      }
      if (!this.form['mobile']) {
        return Toast(this.$t('m.20006'))
      }
      Toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      this.form.h5platform = this.getOS()
      this.$api
        .saveContactus(this.form)
        .then((res) => {
          if (res.code == 200) {
            setTimeout(() => {
              Toast.success(this.$t('m.20017'))
              this.$refs[formName].resetFields()
              this.checked = false
            }, 500)
          } else {
            setTimeout(() => {
              Toast.fail(this.$t('m.20020') + '：' + res.message)
            }, 500)
          }
        })
        .catch((err) => {
          setTimeout(() => {
            Toast.fail(this.$t('m.20020') + '：' + err.message)
          }, 500)
        })
    },

    openMenu() {
      document.documentElement.style.overflow = 'hidden'
      document.documentElement.style.position = 'fixed'
      document.body.scroll = 'no'
    },
    closeMenu() {
      document.documentElement.style.overflow = 'scroll'
      document.documentElement.style.position = 'relative'
      document.body.scroll = 'yes'
    },

    // 获取用户代理系统类型
    getMobile() {
      const ua = navigator.userAgent.toLowerCase()
      if (ua.indexOf('iphone') != -1) {
        this.type = 1
        return 'ios'
      } else if (ua.indexOf('android') != -1) {
        this.type = 0
        return 'android'
      } else if (ua.indexOf('windows') != -1) {
        return 'windows'
      } else {
        return 'none'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: Alibaba-PuHuiTi-R;
  src: url('../fonts/Alibaba-PuHuiTi-Regular.ttf');
}
@font-face {
  font-family: Alibaba-PuHuiTi-M;
  src: url('../fonts/Alibaba-PuHuiTi-Bold.ttf');
}

@mixin aliPuHuiTiR {
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi, Arial, Helvetica, sans-serif;
}
@mixin aliPuHuiTiM {
  font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi, Arial, Helvetica, sans-serif;
}

@mixin title($size, $color) {
  font-size: vv($size);
  @include aliPuHuiTiM;
  font-weight: normal;
  color: $color;
}
@mixin desc($size, $color) {
  font-size: vv($size);
  @include aliPuHuiTiR;
  font-weight: normal;
  color: $color;
}

.wrap {
  overflow-x: hidden;
  background-color: #ffa523;
  .header {
    @include flex($direction: column);
    padding-top: vv(31);
    background: linear-gradient(360deg, #ffa523 0%, #ff744d 80%, #ff5800 100%);
    img {
      width: 100%;
    }
  }

  .contact {
    padding: vv(9) vv(16) 0;
    .contact-box {
      padding: vv(20) vv(20) vv(16);
      background: linear-gradient(180deg, #fbf5eb 0%, #fff6e7 54%, #fff6e7 100%);
      border-radius: vv(16);
      .title {
        @include title(24, #ff6200);
        text-align: center;
      }
      .text {
        text-align: center;
        margin-top: vv(8);
        span {
          display: inline-block;
          padding: vv(3) vv(12);
          border-radius: vv(15);
          border: vv(1) solid #ff5b08;
          @include desc(16, #ff5b08);
        }
      }
      .form {
        margin-top: vv(22);
        ::v-deep .el-input__inner {
          border: 0;
          border-radius: vv(8);
          @include title(16, #333);
          padding: vv(10) vv(12);
        }
        ::v-deep .el-input__inner::-webkit-input-placeholder {
          /* 使用webkit内核的浏览器 */
          @include desc(16, #c1c1c1);
        }
        ::v-deep .el-input__inner:-moz-placeholder {
          /* Firefox版本4-18 */
          @include desc(16, #c1c1c1);
        }
        ::v-deep .el-input__inner::-moz-placeholder {
          /* Firefox版本19+ */
          @include desc(16, #c1c1c1);
        }
        ::v-deep .el-input__inner:-ms-input-placeholder {
          /* IE浏览器 */

          @include desc(16, #c1c1c1);
        }
        ::v-deep .el-input__inner:focus,
        ::v-deep .el-textarea__inner:focus {
          border-color: $red;
        }
        .el-form-item {
          margin-bottom: vv(12);
        }
        .agree {
          @include flex($align: center);
          img {
            width: vv(20);
            height: vv(20);
            flex-shrink: 0;
          }
          span {
            @include desc(14, #ef7131);
            margin-left: vv(4);
          }
        }
        .el-button {
          margin-top: vv(20);
          width: 100%;
          @include title(18, #fff);
          background: linear-gradient(360deg, #ff5800 0%, #ff744d 48%, #ffa523 100%);
          border-radius: vv(24);
          padding-top: vv(10);
          padding-bottom: vv(10);
          border: 0;
          box-sizing: border-box;
          line-height: vv(28);
        }
      }
    }
  }

  .icatering {
    margin-top: vv(17);
    .title {
      img {
        width: 100%;
      }
    }
    .content {
      margin-left: vv(20);
      margin-right: vv(20);
      background: linear-gradient(180deg, #ffd6ab 0%, #fffcfb 100%);
      border-radius: vv(16);
    }
  }
  .function-features {
    .content {
      @include flex($wrap: wrap);
      margin-top: vv(-32);
      padding: vv(43) vv(20) vv(4);
      .content-item {
        margin-bottom: vv(16);
        width: 25%;
        @include flex($direction: column, $align: center);
        img {
          width: vv(48);
          height: vv(48);
          flex-shrink: 0;
        }
        p {
          margin-top: vv(8);
          @include desc(16, #4d4d4d);
        }
      }
    }
  }
  .business-scenario {
    .content {
      @include flex($direction: column);
      margin-top: vv(-62);
      padding: vv(65) vv(20) vv(20);
      .content-item {
        margin-top: vv(12);
        @include flex($align: center);
        img {
          width: vv(64);
          height: vv(64);
          flex-shrink: 0;
        }
        .content-item-r {
          margin-left: vv(8);
          p.title {
            @include title(24, #ff5903);
            line-height: vv(30);
            font-weight: 500;
            font-style: italic;
          }
          p {
            margin-top: vv(4);
            @include desc(16, #4d4d4d);
          }
        }
      }
    }
  }

  .catering-advantage {
    .content {
      @include flex($wrap: wrap);
      margin-top: vv(-35);
      padding: vv(46) vv(20) vv(1);
      .content-item {
        margin-bottom: vv(16);
        width: 33.33%;
        @include flex($direction: column, $align: center);
        img {
          width: vv(100);
          height: vv(100);
          flex-shrink: 0;
        }
        p {
          margin-top: vv(8);
          @include desc(16, #333);
        }
      }
    }
  }
  .catering-device {
    .content {
      @include flex($wrap: wrap);
      margin-top: vv(-104);
      padding: vv(115) vv(20) vv(4);
      .content-item {
        margin-bottom: vv(16);
        width: 33.33%;
        @include flex($direction: column, $align: center);
        img {
          width: vv(101);
          height: vv(140);
          flex-shrink: 0;
        }
        p {
          margin-top: vv(8);
          @include desc(16, #333);
        }
      }
    }
  }

  .scene-coverage {
    .content {
      @include flex($wrap: wrap);
      margin-top: vv(-104);
      padding: vv(115) 0 vv(8);
      .content-item {
        margin-bottom: vv(12);
        border-radius: vv(8);
        position: relative;
        text-align: center;
        width: 50%;
        .img-box {
          position: relative;
          img {
            width: vv(157);
            height: vv(106);
            flex-shrink: 0;
          }
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: vv(157);
            height: vv(106);
            background-color: rgba(0, 0, 0, 0.4);
            border-radius: vv(8);
            z-index: 2;
          }
        }
        p {
          position: absolute;
          bottom: vv(16);
          left: 50%;
          z-index: 3;
          transform: translateX(-50%);
          @include desc(16, #fff);
        }
      }
    }
  }

  .footer {
    margin-top: vv(24);
    margin-bottom: vv(128);
    .item {
      margin-bottom: vv(8);
      @include flex($justify: center, $align: center);
      img {
        width: vv(20);
        height: vv(20);
        flex-shrink: 0;
      }
      p {
        @include desc(14, #ffeab7);
        a {
          color: #ffeab7;
          text-decoration: underline;
        }
      }
      .item-t {
        @include flex;
        p {
          margin-left: vv(2);
        }
        &:not(:last-child) {
          margin-right: vv(37);
        }
      }
    }
    &.hideshow {
      margin-bottom: vv(12);
    }
  }

  .box {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    .content {
      margin: 0 vv(16) vv(20);
      height: vv(92);
      background-color: #fff;
      box-shadow: 0 vv(3) vv(9) 0 rgba(0, 0, 0, 0.05);
      border-radius: vv(13);
      position: relative;
      transition: height 0.3s;
      box-sizing: border-box;
      .content-t {
        transition: all 0.3s;
        position: absolute;
        top: 50%;
        left: vv(16);
        transform: translateY(-50%);
        img {
          width: vv(64);
          height: vv(64);
          flex-shrink: 0;
        }
      }
      .content-m {
        transition: all 0.3s;
        position: absolute;
        top: 50%;
        left: vv(92);
        transform: translateY(-50%);
        div.content-m-title {
          font-size: vv(16);
          @include fontSemibold;
          font-weight: 600;
          color: #333;
        }
        div.content-m-desc {
          font-size: vv(13);
          @include fontMedium;
          font-weight: 500;
          color: #a1a1a1;
        }
        span.count {
          margin-left: vv(4);
          font-size: vv(12);
          @include fontRegular;
          color: #666;
        }
      }
      .content-b {
        transition: all 0.3s;
        position: absolute;
        top: 50%;
        right: vv(12);
        transform: translateY(-50%);
        width: vv(100);
        background-color: #ee1c22;
        border-radius: 24px;
        @include title(16, #fff);
        padding: vv(12) 0;
        text-align: center;
      }
      .content-text {
        display: none;
        transition: display 0.1s;
        position: absolute;
        bottom: vv(14);
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        p {
          text-align: center;
          font-size: vv(12);
          @include fontRegular;
          color: #a1a1a1;
          &:last-child {
            margin-top: vv(2);
          }
          > a {
            color: #a1a1a1;
          }
        }
      }

      &.first-screen {
        height: vv(224);
        .content-t {
          position: absolute;
          top: vv(-32);
          left: 50%;
          transform: translateX(-50%);
        }
        .content-m {
          position: absolute;
          top: vv(36);
          left: 50%;
          transform: translateX(-50%);
          margin-bottom: vv(12);
          @include flex($direction: column, $align: center);
          .rate {
            margin-top: vv(2);
            margin-bottom: vv(8);
          }
        }
        .content-b {
          position: absolute;
          top: vv(120);
          left: 50%;
          transform: translateX(-50%);
          @include title(18, #fff);
          background-color: #ee1c22;
          text-align: center;
          padding: vv(10) 0;
          width: vv(338);
          border-radius: vv(24);
        }
        .content-text {
          display: block;
        }
      }
    }
    &.hideshow {
      position: static;
    }
  }

  .down-box {
    ::v-deep .el-dialog {
      background-image: linear-gradient(360deg, #ffffff 0%, #ffffff 71%, #fde5e5 84%, #ffc9c9 100%);
      border-radius: vv(16);
      position: relative;
    }
    ::v-deep .el-dialog__body {
      padding: vv(12) vv(16) vv(24);
    }
    .logo {
      position: absolute;
      top: vv(-32);
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: vv(64);
        height: vv(64);
      }
    }
    .title {
      text-align: center;
      @include fontMedium;
      font-size: vv(18);
      font-weight: 500;
      color: #333;
    }
    .list {
      margin-top: vv(8);
      .item {
        padding: vv(8) vv(6);
        margin-top: vv(16);
        background: #ffeaea;
        border-radius: vv(4);
        @include flex($align: center);
        .item-l {
          width: vv(126);
          height: vv(104);
          flex-shrink: 0;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .item-r {
          flex: 1;
          @include flex($direction: column, $align: center);
          p {
            font-size: vv(16);
            @include fontMedium;
            font-weight: 500;
            color: #333;
          }
          .btn {
            width: vv(164);
            margin-top: vv(14);
            padding: vv(9) 0;
            background-color: #ee1c22;
            border-radius: vv(6);
            font-size: vv(16);
            @include fontMedium;
            font-weight: 500;
            color: #fff;
            text-align: center;
          }
        }
      }
    }
  }
  ::v-deep .el-dialog__wrapper {
    background-color: rgba(0, 0, 0, 0.8);
  }
  .arrow-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 3001;
    display: none;
    .arrow {
      text-align: right;
      margin-right: vv(50);
      img {
        width: vv(124);
        height: vv(101);
      }
    }
    .content {
      margin-top: vv(4);
      margin-left: vv(58);
      p {
        font-size: vv(18);
        @include fontRegular;
        color: #fff;
        &:last-child {
          margin-top: vv(4);
        }
      }
      img {
        width: vv(24);
        height: vv(24);
      }
    }
    &.show {
      display: block;
    }
  }
}

@media (min-width: 768px) {
  $pcMainWidth: 640px;
  @function vv($v) {
    @return $v/12.8 + vw;
  }

  @mixin title($size, $color) {
    font-size: vv($size);
    @include aliPuHuiTiM;
    font-weight: normal;
    color: $color;
  }
  @mixin desc($size, $color) {
    font-size: vv($size);
    @include aliPuHuiTiR;
    font-weight: normal;
    color: $color;
  }

  .wrap {
    max-width: $pcMainWidth;
    margin: 0 auto;
    .header {
      padding-top: vv(31);
    }

    .contact {
      padding: vv(9) vv(16) 0;
      .contact-box {
        padding: vv(20) vv(20) vv(16);
        border-radius: vv(16);
        .title {
          @include title(24, #ff6200);
        }
        .text {
          margin-top: vv(8);
          span {
            padding: vv(3) vv(12);
            border-radius: vv(15);
            border: vv(1) solid #ff5b08;
            @include desc(16, #ff5b08);
          }
        }
        .form {
          margin-top: vv(22);
          ::v-deep .el-input__inner {
            border-radius: vv(8);
            @include title(16, #333);
            padding: vv(10) vv(12);
          }
          ::v-deep .el-input__inner::-webkit-input-placeholder {
            /* 使用webkit内核的浏览器 */
            @include desc(16, #c1c1c1);
          }
          ::v-deep .el-input__inner:-moz-placeholder {
            /* Firefox版本4-18 */
            @include desc(16, #c1c1c1);
          }
          ::v-deep .el-input__inner::-moz-placeholder {
            /* Firefox版本19+ */
            @include desc(16, #c1c1c1);
          }
          ::v-deep .el-input__inner:-ms-input-placeholder {
            /* IE浏览器 */

            @include desc(16, #c1c1c1);
          }
          .el-form-item {
            margin-bottom: vv(12);
          }
          .agree {
            @include flex($align: center);
            img {
              width: vv(20);
              height: vv(20);
              cursor: pointer;
            }
            span {
              @include desc(14, #ef7131);
              margin-left: vv(4);
              cursor: pointer;
            }
          }
          .el-button {
            margin-top: vv(20);
            @include title(18, #fff);
            border-radius: vv(24);
            padding-top: vv(10);
            padding-bottom: vv(10);
            line-height: vv(28);
          }
        }
      }
    }

    .icatering {
      margin-top: vv(17);
      .content {
        margin-left: vv(20);
        margin-right: vv(20);
        border-radius: vv(16);
      }
    }
    .function-features {
      .content {
        margin-top: vv(-32);
        padding: vv(43) vv(20) vv(4);
        .content-item {
          margin-bottom: vv(16);
          img {
            width: vv(48);
            height: vv(48);
          }
          p {
            margin-top: vv(8);
            @include desc(16, #4d4d4d);
          }
        }
      }
    }
    .business-scenario {
      .content {
        margin-top: vv(-62);
        padding: vv(65) vv(20) vv(20);
        .content-item {
          margin-top: vv(12);
          img {
            width: vv(64);
            height: vv(64);
          }
          .content-item-r {
            margin-left: vv(8);
            p.title {
              @include title(24, #ff5903);
              line-height: vv(30);
            }
            p {
              margin-top: vv(4);
              @include desc(16, #4d4d4d);
            }
          }
        }
      }
    }

    .catering-advantage {
      .content {
        margin-top: vv(-35);
        padding: vv(46) vv(20) vv(1);
        .content-item {
          margin-bottom: vv(16);
          img {
            width: vv(100);
            height: vv(100);
          }
          p {
            margin-top: vv(8);
            @include desc(16, #333);
          }
        }
      }
    }
    .catering-device {
      .content {
        margin-top: vv(-104);
        padding: vv(115) vv(20) vv(4);
        .content-item {
          margin-bottom: vv(16);
          img {
            width: vv(101);
            height: vv(140);
          }
          p {
            margin-top: vv(8);
            @include desc(16, #333);
          }
        }
      }
    }

    .scene-coverage {
      .content {
        margin-top: vv(-104);
        padding: vv(115) 0 vv(8);
        .content-item {
          margin-bottom: vv(12);
          border-radius: vv(8);
          .img-box {
            img {
              width: vv(157);
              height: vv(106);
            }
            &:after {
              width: vv(157);
              height: vv(106);
              border-radius: vv(8);
            }
          }
          p {
            bottom: vv(16);
            @include desc(16, #fff);
          }
        }
      }
    }

    .footer {
      margin-top: vv(24);
      margin-bottom: vv(128);
      .item {
        margin-bottom: vv(8);
        img {
          width: vv(20);
          height: vv(20);
        }
        p {
          @include desc(14, #ffeab7);
        }
        .item-t {
          p {
            margin-left: vv(2);
          }
          &:not(:last-child) {
            margin-right: vv(37);
          }
        }
      }
      &.hideshow {
        margin-bottom: vv(12);
      }
    }

    .box {
      max-width: $pcMainWidth;
      left: 50%;
      transform: translateX(-50%);
      .content {
        margin: 0 vv(16) vv(20);
        height: vv(92);
        background-color: #fff;
        box-shadow: 0 vv(3) vv(9) 0 rgba(0, 0, 0, 0.05);
        border-radius: vv(13);
        position: relative;
        transition: height 0.3s;
        box-sizing: border-box;
        .content-t {
          transition: all 0.3s;
          position: absolute;
          top: 50%;
          left: vv(16);
          transform: translateY(-50%);
          img {
            width: vv(64);
            height: vv(64);
            flex-shrink: 0;
          }
        }
        .content-m {
          transition: all 0.3s;
          position: absolute;
          top: 50%;
          left: vv(92);
          transform: translateY(-50%);
          div.content-m-title {
            font-size: vv(16);
            @include fontSemibold;
            font-weight: 600;
            color: #333;
          }
          div.content-m-desc {
            font-size: vv(13);
            @include fontMedium;
            font-weight: 500;
            color: #a1a1a1;
          }
          span.count {
            margin-left: vv(4);
            font-size: vv(12);
            @include fontRegular;
            color: #666;
          }
        }
        .content-b {
          transition: all 0.3s;
          position: absolute;
          top: 50%;
          right: vv(12);
          transform: translateY(-50%);
          width: vv(100);
          background-color: #ee1c22;
          border-radius: 24px;
          @include title(16, #fff);
          padding: vv(12) 0;
          text-align: center;
        }
        .content-text {
          display: none;
          transition: display 0.1s;
          position: absolute;
          bottom: vv(14);
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          p {
            text-align: center;
            font-size: vv(12);
            @include fontRegular;
            color: #a1a1a1;
            &:last-child {
              margin-top: vv(2);
            }
            > a {
              color: #a1a1a1;
            }
          }
        }

        &.first-screen {
          height: vv(224);
          .content-t {
            position: absolute;
            top: vv(-32);
            left: 50%;
            transform: translateX(-50%);
          }
          .content-m {
            position: absolute;
            top: vv(36);
            left: 50%;
            transform: translateX(-50%);
            margin-bottom: vv(12);
            @include flex($direction: column, $align: center);
            .rate {
              margin-top: vv(2);
              margin-bottom: vv(8);
            }
          }
          .content-b {
            position: absolute;
            top: vv(120);
            left: 50%;
            transform: translateX(-50%);
            @include title(18, #fff);
            background-color: #ee1c22;
            text-align: center;
            padding: vv(10) 0;
            width: vv(338);
            border-radius: vv(24);
          }
          .content-text {
            display: block;
          }
        }
      }
    }
  }
}
</style>
